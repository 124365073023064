import React from 'react'
import styles from './NotFound.module.css'
import notfoundImage from '../../assets/images/notfound.png'

export default function NotFound() {
  return <>
  <div className="container">

 <img className='w-100 p-5 my-5' src={notfoundImage} alt="" srcset="" />
  </div>
  </>
}
